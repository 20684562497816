<template>
  <v-card class="pa-5">
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <validation-provider
        v-slot="{ errors }"
        rules="required|url"
        name="Href"
      >
        <v-text-field
          v-model="value.src"
          label="Script Link"
          outlined
          required
          :error-messages="errors"
        />
      </validation-provider>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MScriptForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null
  })
}
</script>
